// extracted by mini-css-extract-plugin
export var ArtistDescription = "Noc-module--ArtistDescription--cS0ln";
export var ArtistInfos = "Noc-module--ArtistInfos--DIOWR";
export var ButtonWrapper = "Noc-module--ButtonWrapper --oMYBj";
export var CardWrapper = "Noc-module--CardWrapper--cflR6";
export var CarrouselWrapper2 = "Noc-module--CarrouselWrapper2--+1GU3";
export var Citations = "Noc-module--Citations--J0af-";
export var DescriptionWrapper = "Noc-module--DescriptionWrapper--WEtCr";
export var ImageWrapper = "Noc-module--ImageWrapper--jPbEx";
export var LinkWrapper = "Noc-module--LinkWrapper--1CYvi";
export var MobileProtrait = "Noc-module--MobileProtrait--2hd1E";
export var More = "Noc-module--More--n5UI4";
export var MoreButton = "Noc-module--MoreButton--k12jd";
export var NameWrapper = "Noc-module--NameWrapper--Ctb4N";
export var PdpWrapper = "Noc-module--PdpWrapper--fRNEO";
export var PhotosWrapper = "Noc-module--PhotosWrapper--atBvc";
export var ProfilWrapper = "Noc-module--ProfilWrapper--G67lo";
export var TitleWrapper = "Noc-module--TitleWrapper--gniVT";
export var Wrapper = "Noc-module--Wrapper--fJiZO";